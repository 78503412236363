<template>
    <div class="bg-white border border-beige-dark overflow-hidden flex rounded-lg">
        <div class="flex-none bg-beige-dark w-24 flex items-center justify-center text-4xl">
            <svg class="w-10 h-10" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.24514 1.78378C5.24514 0.948385 5.92266 0.271179 6.75775 0.271179H7.76615C8.60124 0.271179 9.27876 0.948385 9.27876 1.78378V12.8762C9.27876 13.7113 8.60124 14.3888 7.76615 14.3888H6.75775C5.92266 14.3888 5.24514 13.7113 5.24514 12.8762V1.78378ZM0.203125 7.83421C0.203125 6.99912 0.880331 6.3216 1.71573 6.3216H2.72413C3.55922 6.3216 4.23674 6.99912 4.23674 7.83421V12.8762C4.23674 13.7113 3.55922 14.3888 2.72413 14.3888H1.71573C0.880331 14.3888 0.203125 13.7113 0.203125 12.8762V7.83421ZM12.8082 2.28799C13.6433 2.28799 14.3208 2.96551 14.3208 3.80059V12.8762C14.3208 13.7113 13.6433 14.3888 12.8082 14.3888H11.7998C10.9647 14.3888 10.2872 13.7113 10.2872 12.8762V3.80059C10.2872 2.96551 10.9647 2.28799 11.7998 2.28799H12.8082Z" fill="currentColor"/>
            </svg>
        </div>
        <div class="flex-grow p-8">
            <h3 class="text-lg font-bold leading-tight">{{dashboard.name}}</h3>
            <!-- <div v-if="dashboard.widgets">
                {{dashboard.widgets.length}} {{dashboard.widgets.length != 1 ? 'widgets' : 'widget'}}
            </div>
            <div v-else>
                Ingen widgets
            </div> -->
            
            Oppdatert {{dashboard.updatedAt | moment('lll')}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dashboard: {
            type: Object,
            required: true,
        }
    },
    
    data() {
        return {
            loading: null,
        }
    },
}
</script>

<style lang="scss">
</style>